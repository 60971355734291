
import React, { useState }  from "react";
import { useUserDataContext } from '../../contexts/userDataProvider';
import userAPI from '../../services/userAPI';
import { useEdit } from '../common/fetchData.hooks';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import ChangeLanguage from './ChangeLanguage';
import SetFeeding from './SetFeeding';
import AddCoworkers from './coworkers/AddCoworkers';
import CoworkerAcceptedRequest from './coworkers/CoworkerAcceptedRequest';

const UserSettings = ({
  setIsSidePannelLoading,
  setSidePannelError,
  isApprovedCoworker,
  workSpaceEmail,
  workSpaceId,
  reloadCoworkerRequestData
}) => {
  const [settingsToSave, setSettingsToSave] = useState({}); // { selectedLocale: locale }

  const { translate, data: userData, reloadData } = useUserDataContext();

  const {
    selectedLocale,
    isDisplayFeedingOption,
    nickname
  } = userData ? userData[0] : '';

  const { isLoading, error, sendEditDataRequest } = useEdit(
    (data) => userAPI.editUser(data),
    settingsToSave,
    () => reloadData()
  );

  const handleSaveEditData = (itemToSave) => {
    setSettingsToSave(itemToSave)
    sendEditDataRequest();
  };

  return (
    <div className="user-settings-wrap">
      <Loader 
        setIsLoading={isLoading}
      />
      <ErrorAlert
        error={error}
      />
      <h5>{translate('userSettings')}</h5>
      <ChangeLanguage
        selectedLocale={selectedLocale}
        handleSaveEditData={handleSaveEditData}
        translate={translate}
      />
      <SetFeeding
        isDisplayFeedingOption={isDisplayFeedingOption}
        handleSaveEditData={handleSaveEditData}
        translate={translate}
      />
      {isApprovedCoworker ? (
        <>
          <h5>{translate('acceptedWorkspaceAccess')}</h5>
          <CoworkerAcceptedRequest
            translate={translate}
            workSpaceEmail={workSpaceEmail}
            workSpaceId={workSpaceId}
            reloadData={reloadCoworkerRequestData}
            setIsLoading={setIsSidePannelLoading}
            setError={setSidePannelError}
          />
        </>
      ) : (
        <>
          <h5>{translate('grantAccess')}</h5>
          <AddCoworkers
            nickname={nickname}  // TODO: ? rename prop to userEmail
            translate={translate}
            setIsLoading={setIsSidePannelLoading}
            setError={setSidePannelError}
          />
        </>
      )}
    </div>
  );
};

export default UserSettings;
